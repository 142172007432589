import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import maintenanceReducer from 'components/content/maintenance/maintenanceSlice'
import ticketReducer from 'components/content/ticket/cleaningSlice'
import notifierReducer from 'components/notifier/notifierSlice'

const persistTicketConfig = {
    key: 'cleaning',
    storage,
}

const persistedTicketReducer = persistReducer(
    persistTicketConfig,
    ticketReducer
)

const store = configureStore({
    reducer: {
        cleaning: persistedTicketReducer,
        maintenance: maintenanceReducer,
        notifier: notifierReducer,
    },
    middleware: [thunk],
})

const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export { persistor }
export default store
