import { faBroom, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useState } from 'react'
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormGroup,
    Modal,
    Row,
    Stack,
} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useLeftTime } from 'components/common/hooks'
import { CurrentTicketResponse, Child, AdditionalService } from './types'
import UpdateTicketConfirmation from './UpdateTicketConfirmation'
import { clearCleaningState } from './cleaningSlice'
import { formatDateToLocaleTimeString } from '../common/utils'

interface TicketProps {
    response: CurrentTicketResponse
}

const TicketItem = ({ response }: TicketProps) => {
    const dispatch = useDispatch()

    const [showTicket, setShowTicket] = useState<boolean>(true)
    const [
        showUpdateTicketConfirmation,
        setShowUpdateTicketConfirmation,
    ] = useState<boolean>(false)

    const latestExecutionDate = new Date(
        response.ticket.latestExecutionTimestamp
    )
    const plannedExecutionDate = new Date(
        response.ticket.plannedExecutionTimestamp
    )

    dayjs.extend(relativeTime)
    const dayDiff = dayjs(latestExecutionDate).diff(dayjs(), 'days')
    const dueAlertColor =
        dayDiff < 3 ? `ds-color-${dayDiff < 1 ? 'danger' : 'warning'}` : ''

    const { leftDays, leftHours } = useLeftTime(latestExecutionDate)

    const handleClose = () => {
        setShowTicket(false)
        dispatch(clearCleaningState())
    }

    return (
        <>
            <UpdateTicketConfirmation
                providerId={response.ticket.providerId}
                ticketId={response.ticket.id}
                showModal={showUpdateTicketConfirmation}
                setShowModal={setShowUpdateTicketConfirmation}
                setShowTicket={setShowTicket}
            />
            <Modal show={showTicket} onHide={handleClose} className='ds-modal'>
                <Modal.Header closeButton>
                    <div className='ds-icon-modal ms-auto flex-shrink-1'>
                        <div className='ds-icon-modal-center'>
                            <div className='ds-card-icon d-flex align-items-center justify-content-center'>
                                <i>
                                    <FontAwesomeIcon icon={faBroom} />
                                </i>
                            </div>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='w-100 mb-4'>
                        <small className='text-color-n3'>
                            {response.ticket.shortId}&nbsp;
                            <i className={dueAlertColor}>
                                <FontAwesomeIcon icon={faClock} />
                            </i>
                            &nbsp; fällig&nbsp;
                            {dayjs(latestExecutionDate).format(
                                'DD.MM.YYYY HH:mm'
                            )}
                        </small>
                        <h4 className='text-break fw-bold'>Reinigung</h4>
                        <div className='text-color-n3 ds-card-room-name'>
                            {response.ticket.roomName}
                        </div>
                    </div>
                    <p>
                        <span className='fw-bold'>
                            {response.ticket.objectName}
                        </span>
                        <br />
                        {response.ticket.objectAddress
                            ? response.ticket.objectAddress.street
                            : ''}
                        <br />
                        {response.ticket.objectAddress
                            ? response.ticket.objectAddress.zipcode
                            : ''}{' '}
                        {response.ticket.objectAddress
                            ? response.ticket.objectAddress.city
                            : ''}
                    </p>
                    <div className='ds-bg-section mb-4'>
                        <p className='fw-bold mb-3'>
                            Beginn der geplanten Ausführung
                            <br />
                            {dayjs(plannedExecutionDate).format(
                                'DD.MM.YYYY HH:mm'
                            )}{' '}
                            Uhr
                        </p>
                        noch {leftDays() < 0 ? 0 : leftDays()} Tag(e) und{' '}
                        {leftDays() < 0 ? 0 : leftHours()} Stunden bis Ende der
                        Ausführung
                        <br />
                        <br />
                        (Ausführungszeit endet:{' '}
                        {dayjs(latestExecutionDate).format(
                            'DD.MM.YYYY HH:mm'
                        )}{' '}
                        Uhr)
                    </div>
                    <div className='ds-bg-section mb-4'>
                        <p className='fw-bold mb-3'>
                            Informationen aus der Buchung
                        </p>
                        <Stack direction='vertical'>
                            {response.ticket.adults ? (
                                <Form>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Anreise:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                    response.ticket.arrival
                                                        ? formatDateToLocaleTimeString(
                                                              new Date(
                                                                  response.ticket.arrival
                                                              )
                                                          )
                                                        : 'keine Daten'
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Abreise:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                    response.ticket.departure
                                                        ? formatDateToLocaleTimeString(
                                                              new Date(
                                                                  response.ticket.departure
                                                              )
                                                          )
                                                        : 'keine Daten'
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Anzahl Erwachsene:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={`${
                                                    response.ticket.adults
                                                        ? response.ticket.adults
                                                        : 'keine'
                                                }`}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Anzahl Kinder:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <div
                                                style={{
                                                    display: 'inline',
                                                    float: 'left',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                <ul
                                                    style={{
                                                        paddingLeft: '15px',
                                                    }}
                                                >
                                                    {response.ticket.childs &&
                                                    response.ticket.childs
                                                        .length > 0 ? (
                                                        response.ticket.childs.map(
                                                            (
                                                                child: Child,
                                                                index: number
                                                            ) => (
                                                                <li
                                                                    key={`child-${index}`}
                                                                >
                                                                    {child.age}{' '}
                                                                    Jahre (
                                                                    {
                                                                        child.count
                                                                    }
                                                                    x)
                                                                </li>
                                                            )
                                                        )
                                                    ) : (
                                                        <li
                                                            key={'childsEmpty'}
                                                            style={{
                                                                listStyleType:
                                                                    'none',
                                                                marginLeft:
                                                                    '-15px',
                                                            }}
                                                        >
                                                            {'keine'}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Zusatzleistungen:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <div
                                                style={{
                                                    display: 'inline',
                                                    float: 'left',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                <ul
                                                    style={{
                                                        paddingLeft: '15px',
                                                    }}
                                                >
                                                    {response.ticket
                                                        .additionalServices &&
                                                    response.ticket
                                                        .additionalServices
                                                        .length > 0 ? (
                                                        response.ticket.additionalServices.map(
                                                            (
                                                                item: AdditionalService,
                                                                index: number
                                                            ) => (
                                                                <li
                                                                    key={`addServices-${index}`}
                                                                >
                                                                    {
                                                                        item.as_name
                                                                    }{' '}
                                                                    (
                                                                    {
                                                                        item.as_count
                                                                    }
                                                                    x)
                                                                </li>
                                                            )
                                                        )
                                                    ) : (
                                                        <li
                                                            key={
                                                                'addServicesEmpty'
                                                            }
                                                            style={{
                                                                listStyleType:
                                                                    'none',
                                                                marginLeft:
                                                                    '-15px',
                                                            }}
                                                        >
                                                            {'keine'}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Bemerkungen:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                style={{
                                                    marginTop: '10px',
                                                    padding: '0.5em',
                                                    border: 'solid darkgrey',
                                                }}
                                                as='textarea'
                                                rows={4}
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                    response.ticket.notes
                                                        ? response.ticket.notes
                                                        : 'keine'
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            ) : (
                                'keine Buchung vorhanden'
                            )}
                        </Stack>
                    </div>
                    <div className='ds-bg-section mb-4'>
                        <p className='fw-bold mb-3'>
                            Informationen aus der nächsten Buchung
                        </p>
                        <Stack direction='vertical'>
                            {response.ticket.nextAdults ? (
                                <Form>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Anreise:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                    response.ticket.nextArrival
                                                        ? formatDateToLocaleTimeString(
                                                              new Date(
                                                                  response.ticket.nextArrival
                                                              )
                                                          )
                                                        : 'keine Daten'
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Abreise:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                    response.ticket
                                                        .nextDeparture
                                                        ? formatDateToLocaleTimeString(
                                                              new Date(
                                                                  response.ticket.nextDeparture
                                                              )
                                                          )
                                                        : 'keine Daten'
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Anzahl Erwachsene:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                plaintext
                                                readOnly
                                                defaultValue={`${
                                                    response.ticket.nextAdults
                                                        ? response.ticket
                                                              .nextAdults
                                                        : 'keine'
                                                }`}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Anzahl Kinder:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <div
                                                style={{
                                                    display: 'inline',
                                                    float: 'left',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                <ul
                                                    style={{
                                                        paddingLeft: '15px',
                                                    }}
                                                >
                                                    {response.ticket
                                                        .nextChilds &&
                                                    response.ticket.nextChilds
                                                        .length > 0 ? (
                                                        response.ticket.nextChilds.map(
                                                            (
                                                                child: Child,
                                                                index: number
                                                            ) => (
                                                                <li
                                                                    key={`nextChilds-${index}`}
                                                                >
                                                                    {child.age}{' '}
                                                                    Jahre (
                                                                    {
                                                                        child.count
                                                                    }
                                                                    x)
                                                                </li>
                                                            )
                                                        )
                                                    ) : (
                                                        <li
                                                            key={
                                                                'nextChildsEmpty'
                                                            }
                                                            style={{
                                                                listStyleType:
                                                                    'none',
                                                                marginLeft:
                                                                    '-15px',
                                                            }}
                                                        >
                                                            {'keine'}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Zusatzleistungen:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <div
                                                style={{
                                                    display: 'inline',
                                                    float: 'left',
                                                    marginTop: '10px',
                                                }}
                                            >
                                                <ul
                                                    style={{
                                                        paddingLeft: '15px',
                                                    }}
                                                >
                                                    {response.ticket
                                                        .nextAdditionalServices &&
                                                    response.ticket
                                                        .nextAdditionalServices
                                                        .length > 0 ? (
                                                        response.ticket.nextAdditionalServices.map(
                                                            (
                                                                item: AdditionalService,
                                                                index: number
                                                            ) => (
                                                                <li
                                                                    key={`nextAddServices-${index}`}
                                                                >
                                                                    {
                                                                        item.as_name
                                                                    }{' '}
                                                                    (
                                                                    {
                                                                        item.as_count
                                                                    }
                                                                    x)
                                                                </li>
                                                            )
                                                        )
                                                    ) : (
                                                        <li
                                                            key={
                                                                'nextAddServicesEmpty'
                                                            }
                                                            style={{
                                                                listStyleType:
                                                                    'none',
                                                                marginLeft:
                                                                    '-15px',
                                                            }}
                                                        >
                                                            {'keine'}
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup as={Row}>
                                        <Form.Label column sm='4'>
                                            Bemerkungen:
                                        </Form.Label>
                                        <Col sm='8'>
                                            <Form.Control
                                                style={{
                                                    marginTop: '10px',
                                                    padding: '0.5em',
                                                    border: 'solid darkgrey',
                                                }}
                                                as='textarea'
                                                rows={4}
                                                plaintext
                                                readOnly
                                                defaultValue={
                                                    response.ticket.nextNotes
                                                        ? response.ticket
                                                              .nextNotes
                                                        : 'keine'
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            ) : (
                                'keine weitere Buchung vorhanden'
                            )}
                        </Stack>
                    </div>
                    <div>
                        <h5 className='fw-bold'>Aufgaben</h5>
                        {response.ticket.content && (
                            <Card className='ds-card mb-3'>
                                <div className='fw-bold mb-3'>
                                    Reinigungsanweisung
                                </div>
                                {response.ticket.content
                                    .split('\n')
                                    .map(function (item, idx) {
                                        return (
                                            <span key={idx}>
                                                {item}
                                                <br />
                                            </span>
                                        )
                                    })}
                            </Card>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className='mb-5'>
                    <Container>
                        <Row>
                            <div className='col-12 col-md-12 col-lg-6'>
                                <Button
                                    variant='secondary'
                                    className='px-4 w-100'
                                    onClick={handleClose}
                                >
                                    Abbrechen
                                </Button>
                            </div>
                            <div className='d-lg-none d-xl-block d-xl-none d-xxl-none d-xxl-block ds-spacer'></div>
                            <div className='col-12 col-md-12 col-lg-6'>
                                <Button
                                    variant='primary'
                                    className='px-4 w-100'
                                    onClick={() => {
                                        setShowTicket(false)
                                        setShowUpdateTicketConfirmation(true)
                                    }}
                                >
                                    Ticket ist fertig
                                </Button>
                            </div>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default TicketItem
