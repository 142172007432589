import { Alert, Col, Row } from 'react-bootstrap'

interface NotifierProps {
    message: string
    variant: string
}

const Notifier = ({ message, variant }: NotifierProps) => (
    <Row className='justify-content-center'>
        <Col>
            <Alert className='text-center' variant={variant}>
                {message}
            </Alert>
        </Col>
    </Row>
)

export default Notifier
