import { Html5QrcodeScanner } from 'html5-qrcode'
import { useEffect, useRef } from 'react'
import { Row } from 'react-bootstrap'

type ScanProps = {
    handleScan: (decodedText: string) => Promise<boolean>
}

const Scan = ({ handleScan }: ScanProps) => {
    const readerRef = useRef<Html5QrcodeScanner | null>(null)

    const onScanSuccess = async (decodedText: string) => {
        const isDataCorrect = await handleScan(decodedText)
        if (isDataCorrect) {
            readerRef.current?.pause()
        }
    }

    useEffect(() => {
        if (!readerRef.current?.getState()) {
            const html5QrcodeScanner = new Html5QrcodeScanner(
                'reader',
                { fps: 10, qrbox: { width: 250, height: 250 } },
                false
            )
            readerRef.current = html5QrcodeScanner
            html5QrcodeScanner.render(onScanSuccess, () => {})
        }
    })

    return (
        <Row className='justify-content-center'>
            <div id='reader' className='ds-code-scanner-border' />
        </Row>
    )
}

export default Scan
