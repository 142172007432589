import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Notification } from './types'

const initialState: Notification = {
    message: null,
    variant: '',
}

export const notifierSlice = createSlice({
    name: 'notifier',
    initialState: initialState,
    reducers: {
        setNotification: (state, action: PayloadAction<Notification>) => {
            state.message = action.payload.message
            state.variant = action.payload.variant
        },
        clearNotification: state => {
            state.message = null
            state.variant = ''
        },
    },
})

export const { setNotification, clearNotification } = notifierSlice.actions

export default notifierSlice.reducer
