import {
    clearCleaningState,
    setTicket,
} from 'components/content/ticket/cleaningSlice'
import {
    clearNotification,
    setNotification,
} from 'components/notifier/notifierSlice'
import { isEmpty } from 'lodash'
import { AppDispatch } from './store'
import { prepareHeaders } from './utils'

const API_URL = process.env.REACT_APP_API_URL

const fetchTicket = async (bsRoomId: string, dispatch: AppDispatch) =>
    await fetch(`${API_URL}/current-ticket/${bsRoomId}`)
        .then(response => response.json())
        .then(result => {
            if (isEmpty(result)) {
                dispatch(
                    setNotification({
                        message:
                            'Es gibt keine verfügbaren Tickets für diesen Raum.',
                        variant: 'danger',
                    })
                )
                dispatch(clearCleaningState())

                return null
            } else {
                localStorage.setItem('bsRoomId', bsRoomId)
                dispatch(setTicket(result))
                dispatch(clearNotification())

                return result
            }
        })
        .catch(error => {
            console.log('Error:', error)

            dispatch(
                setNotification({
                    message: 'Ticket nicht gefunden.',
                    variant: 'danger',
                })
            )
            dispatch(clearCleaningState())

            return null
        })

const updateTicketStatusDone = async (
    providerId: string,
    ticketId: string,
    dispatch: AppDispatch
) => {
    await fetch(
        `${API_URL}/provider/${providerId}/ticket/${ticketId}/update-status/done`,
        {
            method: 'PATCH',
            headers: prepareHeaders(
                'update-status-done#' + providerId + '#' + ticketId
            ),
        }
    )
        .then(response => response.json())
        .then(() => {
            dispatch(
                setNotification({
                    message: 'Reinigungsaufgabe wurde erledigt.',
                    variant: 'success',
                })
            )
        })
        .catch(error => {
            dispatch(
                setNotification({
                    message:
                        'Die Reinigungsaufgabe konnte nicht erledigt werden. Bitte versuchen Sie es erneut. ' +
                        error,
                    variant: 'danger',
                })
            )
        })

    dispatch(clearCleaningState())
}

export { fetchTicket, updateTicketStatusDone }
