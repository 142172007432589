const Footer = () => {
    return (
        <footer className='text-center'>
            © 2023 • Destination Solutions •{' '}
            <a
                href='https://www.ds-destinationsolutions.com/impressum'
                target='_blank'
                rel='noreferrer'
            >
                Impressum
            </a>{' '}
            •{' '}
            <a
                href='https://www.ds-destinationsolutions.com/datenschutz'
                target='_blank'
                rel='noreferrer'
            >
                Datenschutz
            </a>
        </footer>
    )
}

export default Footer
