import { Col, Container, Image, Row } from 'react-bootstrap'

import logoImage from 'assets/images/ds-logo.svg'

const NavBar = () => {
    return (
        <nav>
            <Container className='ds-bg-secondary'>
                <Row>
                    <Col>
                        <div className='navbar'>
                            <Image
                                src={logoImage}
                                alt='DS Destination Solutions'
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </nav>
    )
}

export default NavBar
