const ONE_HOUR = 1000 * 60 * 60
const ONE_DAY = 1000 * 60 * 60 * 24

const useLeftTime = (lateDate: Date) => {
    const today = new Date()
    let daysLeft = 0

    const leftDays = () => {
        daysLeft =
            Math.ceil((lateDate.getTime() - today.getTime()) / ONE_DAY) - 1
        return daysLeft
    }

    const leftHours = () => {
        return Math.ceil(
            (lateDate.getTime() - (daysLeft * ONE_DAY + today.getTime())) /
                ONE_HOUR
        )
    }

    return { leftDays, leftHours }
}

export { useLeftTime }
