enum TICKET_KIND {
    CLEANING = 'CLEANING',
    MAINTENANCE = 'MAINTENANCE',
}

interface CurrentTicketResponse {
    ticket: Ticket
    room: Room
}

interface Address {
    city: string
    street: string
    zipcode: string
    additionalInfo?: string
}

interface AdditionalInfo {
    cleaning?: string
    maintenance?: string
}

interface AdditionalService {
    as_count: number
    as_name: string
}

interface Child {
    age: number
    count: number
}

interface Room {
    id: string
    providerId: string
    address: Address
    additionalInfo?: AdditionalInfo
    bsCompanyId: string
    bsObjectId: number
    bsObjectName: string
    bsRoomId: number
    bsRoomName: string
    createdAt: number
    modifiedAt?: number
}

interface Ticket {
    id: string
    shortId: string
    createdAt: number
    kind: TICKET_KIND
    providerId: string
    roomId: string
    bsRoomId: number
    roomName?: string
    objectId: number
    objectName?: string
    objectAddress?: Address
    status: number
    content?: string
    earliestExecutionTimestamp: number
    plannedExecutionTimestamp: number
    latestExecutionTimestamp: number
    reservedFromTimestamp: number
    bsReservationId: string
    adults: number
    additionalServices?: AdditionalService[]
    childs?: Child[]
    notes?: string
    arrival: number
    departure: number
    nextAdults?: number
    nextChilds?: Child[]
    nextAdditionalServices?: AdditionalService[]
    nextNotes?: string
    nextArrival?: number
    nextDeparture?: number
    modifiedAt?: number
    inProgress: string
}

interface TicketScanResult {
    bsRoomId: string
}

interface TicketState {
    roomId: null | string
    ticket: null | CurrentTicketResponse
}

export { TICKET_KIND }
export type {
    CurrentTicketResponse,
    TicketState,
    TicketScanResult,
    Ticket,
    Room,
    Child,
    AdditionalService,
}
