import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Damage = {
    room: string
    provider: string
    description: string
}

const initialState: Damage = {
    room: '',
    provider: '',
    description: '',
}

export const maintenanceSlice = createSlice({
    name: 'maitenance',
    initialState: initialState,
    reducers: {
        setRoom: (state, action: PayloadAction<string>) => {
            state.room = action.payload
        },
        setProvider: (state, action: PayloadAction<string>) => {
            state.provider = action.payload
        },
        setDescription: (state, action: PayloadAction<string>) => {
            state.description = action.payload
        },
    },
})

export const { setRoom, setProvider, setDescription } = maintenanceSlice.actions

export default maintenanceSlice.reducer
