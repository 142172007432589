import { Ticket, TICKET_KIND } from 'components/content/ticket/types'

type CallHeaders = { [key: string]: string }

const getEmptyTicket = () => {
    const ticket: Ticket = {
        id: '',
        shortId: '',
        providerId: '',
        roomId: '',
        bsRoomId: 0,
        objectId: 0,
        adults: 0,
        arrival: 1,
        departure: 1,
        inProgress: 'false',
        bsReservationId: '',
        kind: TICKET_KIND.CLEANING,
        status: 1,
        content: '',
        earliestExecutionTimestamp: 1,
        reservedFromTimestamp: 1,
        latestExecutionTimestamp: 1,
        plannedExecutionTimestamp: 1,
        createdAt: 0,
        modifiedAt: 0,
    }

    return ticket
}

const prepareHeaders = (token: string) => {
    const callHeaders: CallHeaders = {
        'Content-Type': 'application/json',
    }

    if (token) {
        callHeaders['X-Identification'] = `${token}`
    }

    return callHeaders
}

export { prepareHeaders, getEmptyTicket as getEmptyTicketItem }
