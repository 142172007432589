import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { CurrentTicketResponse, TicketScanResult, TicketState } from './types'

const initialState: TicketState = {
    roomId: null,
    ticket: null,
}

export const cleaningSlice = createSlice({
    name: 'cleaning',
    initialState: initialState,
    reducers: {
        setRoomIdFromScanResult: (
            state,
            action: PayloadAction<TicketScanResult>
        ) => {
            state.roomId = action.payload.bsRoomId
        },
        setTicket: (state, action: PayloadAction<CurrentTicketResponse>) => {
            state.ticket = action.payload
        },
        clearCleaningState: state => {
            state.roomId = null
            state.ticket = null
        },
    },
})

export const {
    setRoomIdFromScanResult,
    setTicket,
    clearCleaningState,
} = cleaningSlice.actions

export default cleaningSlice.reducer
